import { RiCalendarCheckLine } from "react-icons/ri";
import { FaCalculator, FaRegChartBar } from "react-icons/fa";
import "./site.scss";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Carousel from "../../components/carousel-component";
import CardComponent from "../../components/card-component";
import { HeaderComponent } from "../../components/header-site";
import { FooterComponent } from "../../components/footer-site";
import { PlansList } from "../../api/interfaces";
import i18next, { t } from "i18next";
import UserService from "../../services/UserService";
import { useLanguage } from "../../contexts/language";
import { transformNumber } from "../../utils/NumberFormat";
const HomeBg = require("../../assets/images/Homebg.jpg");
const WizeWingsLogoM = require("../../assets/images/Logo02Blue6.png");
const DiscoverP1Image1 = require("../../assets/images/DiscoverP1Image1.png");
const DiscoverP1Image2 = require("../../assets/images/DiscoverP1Image2.png");
const SystemWeb = require("../../assets/images/system-web.png");
const SystemWebUS = require("../../assets/images/imagens-molduradas/notebook-us.jpg");
const FlightPlanningSmartAirport = require("../../assets/images/flightplanning-smart-airport.png");
const FlightPlanningSmartAirportUS = require("../../assets/images/imagens-molduradas/flightplanning-us.png");

//carrossel 1
const APP_IMAGE_1 = require("../../assets/images/carrossel-app-images/image-1.png");
const APP_IMAGE_2 = require("../../assets/images/carrossel-app-images/image-2.png");
const APP_IMAGE_3 = require("../../assets/images/carrossel-app-images/image-3.png");
const APP_IMAGE_4 = require("../../assets/images/carrossel-app-images/image-4.png");
const APP_IMAGE_5 = require("../../assets/images/carrossel-app-images/image-5.png");
const APP_IMAGE_6 = require("../../assets/images/carrossel-app-images/image-6.png");
const APP_IMAGE_7 = require("../../assets/images/carrossel-app-images/image-7.png");
const APP_IMAGE_8 = require("../../assets/images/carrossel-app-images/image-8.png");
const APP_IMAGE_9 = require("../../assets/images/carrossel-app-images/image-9.png");

const APP_IMAGE_1_US = require("../../assets/images/carrossel-app-images-us/image-1.jpg");
const APP_IMAGE_2_US = require("../../assets/images/carrossel-app-images-us/image-2.jpg");
const APP_IMAGE_3_US = require("../../assets/images/carrossel-app-images-us/image-3.jpg");
const APP_IMAGE_4_US = require("../../assets/images/carrossel-app-images-us/image-4.jpg");
const APP_IMAGE_5_US = require("../../assets/images/carrossel-app-images-us/image-5.jpg");
const APP_IMAGE_6_US = require("../../assets/images/carrossel-app-images-us/image-6.jpg");
const APP_IMAGE_7_US = require("../../assets/images/carrossel-app-images-us/image-7.jpg");
const APP_IMAGE_8_US = require("../../assets/images/carrossel-app-images-us/image-8.jpg");
const APP_IMAGE_9_US = require("../../assets/images/carrossel-app-images-us/image-9.jpg");

const WEB_IMAGE_1_US = require("../../assets/images/prints-web-en/image-1.jpg");
const WEB_IMAGE_2_US = require("../../assets/images/prints-web-en/image-2.jpg");
const WEB_IMAGE_3_US = require("../../assets/images/prints-web-en/image-3.jpg");
const WEB_IMAGE_4_US = require("../../assets/images/prints-web-en/image-4.jpg");
const WEB_IMAGE_5_US = require("../../assets/images/prints-web-en/image-5.jpg");
const WEB_IMAGE_6_US = require("../../assets/images/prints-web-en/image-6.jpg");

const WEB_IMAGE_1 = require("../../assets/images/prints-web-pt/image-1.png");
const WEB_IMAGE_2 = require("../../assets/images/prints-web-pt/image-2.png");
const WEB_IMAGE_3 = require("../../assets/images/prints-web-pt/image-6.png");
const WEB_IMAGE_4 = require("../../assets/images/prints-web-pt/image-4.png");
const WEB_IMAGE_5 = require("../../assets/images/prints-web-pt/image-3.png");

const AIRPLANE_CLOCK = require("../../assets/images/mdi_airplane-clock.png");
const GET_APP_GOOGLE = require("../../assets/images/get_app_google_btn.png");
const GET_APP_APPLE = require("../../assets/images/get_app_apple_btn.png");

const DATA_CAROUSEL_WEB = [
  WEB_IMAGE_1,
  WEB_IMAGE_2,
  WEB_IMAGE_3,
  WEB_IMAGE_4,
  WEB_IMAGE_5,
];

const APP_WIZEWINGS_APPLE =
  "https://apps.apple.com/us/app/wizewings/id1637461718";
const APP_WIZEWINGS_GOOGLE =
  "https://play.google.com/store/apps/details?id=aero.wizewings.wizewings";

const CardPlan = (props: { info: PlansList; onClick: Function }) => {
  const { info, onClick } = props;
  const [priceInfo, setPriceInfo] = useState<any>({});
  const { currentLanguage } = useLanguage();
  const onButtonClick = () => {
    onClick();
  };

  useEffect(() => {
    setPriceInfo(info.price.find((item) => item.lng === currentLanguage));
  }, [currentLanguage]);

  return (
    <div
      className="container-shadow"
      style={{
        // minWidth: 320,
        width: "70%",
        padding: 20,
        justifyContent: "space-around",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: 20,
        minHeight: 500,
      }}
    >
      <h6 style={{ paddingBottom: 20 }}>{t(info.title)}</h6>
      <p>{t("basicPlanDesc1", { people: info.people })}</p>
      <p>{t("basicPlanDesc2", { aircraft: info.aircraft })}</p>
      <h3 style={{ padding: 30, fontWeight: "bold" }}>
        {transformNumber(priceInfo?.value, priceInfo?.coin)}
      </h3>

      {/* <Button
        text={t("SELECT")}
        onClick={onButtonClick}
        className="custom-button-rounded"
        width={200}
      /> */}
    </div>
  );
};

export default function Site() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [plansList, setPlansList] = useState<PlansList[]>([]);
  const [carouselApp, setCarouselApp] = useState<any[]>([]);
  const [carouselWeb, setCarouselWeb] = useState<any[]>([]);
  const [imageSystemWeb, setImageSystemWeb] = useState<any>();
  const [imageFlightPlanning, setImageFlightPlanning] = useState<any>();
  const { currentLanguage } = useLanguage();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    loadInitial();
  }, []);

  useEffect(() => {
    if (currentLanguage?.includes("pt")) {
      setImageFlightPlanning(FlightPlanningSmartAirportUS);
      setImageSystemWeb(SystemWebUS);
      // setImageFlightPlanning(FlightPlanningSmartAirport);
      // setImageSystemWeb(SystemWeb);
      setCarouselApp([
        APP_IMAGE_1,
        APP_IMAGE_2,
        APP_IMAGE_3,
        APP_IMAGE_4,
        APP_IMAGE_5,
        APP_IMAGE_6,
        APP_IMAGE_7,
        APP_IMAGE_8,
        APP_IMAGE_9,
      ]);
      setCarouselWeb([
        WEB_IMAGE_1,
        WEB_IMAGE_2,
        WEB_IMAGE_3,
        WEB_IMAGE_4,
        WEB_IMAGE_5,
      ]);
    } else {
      setImageFlightPlanning(FlightPlanningSmartAirportUS);
      setImageSystemWeb(SystemWebUS);
      setCarouselApp([
        APP_IMAGE_1_US,
        APP_IMAGE_2_US,
        APP_IMAGE_3_US,
        APP_IMAGE_4_US,
        APP_IMAGE_5_US,
        APP_IMAGE_6_US,
        APP_IMAGE_9_US,
        APP_IMAGE_7_US,
        APP_IMAGE_8_US,
      ]);
      setCarouselWeb([
        WEB_IMAGE_1_US,
        WEB_IMAGE_2_US,
        WEB_IMAGE_3_US,
        WEB_IMAGE_4_US,
        WEB_IMAGE_5_US,
        WEB_IMAGE_6_US,
      ]);
    }
  }, [currentLanguage]);

  const loadInitial = async () => {
    try {
      let lResp = await UserService.getListPlan();

      if (lResp) {
        setPlansList(lResp);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <React.Fragment>
      <div
        className="site"
        style={{ flexDirection: "column", display: "flex", flex: 1 }}
      >
        <HeaderComponent />
        {/* <HeaderResponsiveComponent /> */}

        <div className="content content-container content-space-between">
          <div className="content-item">
            <h3>{t("bestAssistantToManageFlights")}</h3>
            <h5>{t("WizeWingsIsAnApplicationForManaging")}</h5>
            <h5>{t("DesignedForPilotsAndOwners")}</h5>
            <img
              src={imageSystemWeb}
              alt={`System Web`}
              width={"90%"}
              style={{ alignSelf: "center", marginTop: 20, minWidth: 300 }}
            />
          </div>
          <div className="content-item">
            <img
              src={imageFlightPlanning}
              alt={`System Web`}
              width={"80%"}
              style={{ alignSelf: "center", minWidth: 300 }}
            />
          </div>
        </div>

        <div
          id={t("APP")}
          className="content-container content-container-shadow container-wrap-reverse"
        >
          <div className="content-item">
            <Carousel images={carouselApp} width={50} minWidth={300} />
          </div>
          <div className="content-item content-item-highlighted content-space-between ">
            <h3 style={{ minWidth: 200 }}>{t("APP")}</h3>
            <h5>{t("CostsAreStoresAndCanAlwaysBeConsulted")}</h5>
          </div>
        </div>

        <div
          id={t("WEB")}
          className="content-container content-container-shadow content-item-row"
        >
          <div
            className="content-item content-item-highlighted"
            style={{ maxWidth: "50%" }}
          >
            <h3>{t("WEB")}</h3>
          </div>

          <div className="content-item-column">
            <div
              className="content-item content-item-row container-wrap"
              style={{ marginBottom: 20 }}
            >
              <CardComponent
                IconComponent={() => (
                  <div>
                    <RiCalendarCheckLine className="icon-default" />
                    <img
                      src={AIRPLANE_CLOCK}
                      alt={`Airplane Clock`}
                      width={25}
                      height={25}
                      style={{ marginLeft: 5, alignSelf: "center" }}
                    />
                  </div>
                )}
                title={t("SCHEDULEDFLIGHTS")}
                // text={t("scheduledFlightsDescription")}
                text=""
                loading={false}
              />
              <CardComponent
                IconComponent={() => <FaCalculator className="icon-default" />}
                title={t("COSTSCONTROL")}
                // text={t("costsControlDescription")}
                text=""
                loading={false}
              />
              <CardComponent
                IconComponent={() => <FaRegChartBar className="icon-default" />}
                title={t("OPERATIONALREPORTS")}
                // text={t("operationReportsDescription")}
                text=""
                loading={false}
              />
            </div>

            <div
              className="content-item  content-space-between "
              style={{ alignItems: "center" }}
            >
              <Carousel images={carouselWeb} width={80} minWidth={300} />
            </div>
          </div>
          {/* <div className="content-item "></div> */}
        </div>

        <div
          className="content-container content-container-shadow"
          style={{ justifyContent: "center" }}
          id={t("PLANS")}
        >
          <div
            className=" content-item"
            style={{
              // maxWidth: "50%",
              minWidth: "100%",
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="content-item content-item-highlighted"
              style={{
                maxWidth: "50%",
                minWidth: 330,
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              <h3>{t("CHOOSEYOURPLAN")}</h3>
              <p>{t("SelectThePlanBestToYourNeeds")}</p>
            </div>

            {/* <div className="content-item "></div> */}
          </div>

          {/* {plansList.map((plan, index) => (
            <div key={index} className="content-item" style={{ margin: 10 }}>
              <CardPlan info={plan} onClick={() => null} />
            </div>
          ))} */}

          <div className="content-item container-shadow" style={{ margin: 10 }}>
            <h5
              className="font-default"
              style={{ padding: 20, paddingBottom: 0 }}
            >
              4 {t("users").toUpperCase()}
            </h5>
            <h5 className="font-subtitle" style={{ padding: 20 }}>
              1 {t("airplane").toUpperCase()}
            </h5>
          </div>

          <div className="content-item container-shadow" style={{ margin: 10 }}>
            <h5
              className="font-default"
              style={{ padding: 20, paddingBottom: 0 }}
            >
              12 {t("users").toUpperCase()}
            </h5>
            <h5 className="font-subtitle" style={{ padding: 20 }}>
              3 {t("airplanes").toUpperCase()}
            </h5>
          </div>

          <div className="content-item container-shadow" style={{ margin: 10 }}>
            <h5
              className="font-default"
              style={{ padding: 20, paddingBottom: 0 }}
            >
              20 {t("users").toUpperCase()}
            </h5>
            <h5 className="font-subtitle" style={{ padding: 20 }}>
              6 {t("airplanes").toUpperCase()}
            </h5>
          </div>

          {/* <div className="content-item" style={{ margin: 10 }}>
            <CardPlan
              title={t("PREMIUMPLAN")}
              description1={t("premiumPlanDesc1")}
              description2={t("premiumPlanDesc2")}
              price={"RS 100,00"}
              onClick={() => null}
            />

          </div> */}
        </div>

        <div
          className="content-item content-item-row container-wrap"
          style={{ marginBottom: 20 }}
        >
          <a href={APP_WIZEWINGS_APPLE} target="_blank">
            <img
              src={GET_APP_APPLE}
              alt={`Airplane Clock`}
              width={210}
              height={67}
              style={{ marginLeft: 5, alignSelf: "center" }}
            />
          </a>
          <a href={APP_WIZEWINGS_GOOGLE} target="_blank">
            <img
              src={GET_APP_GOOGLE}
              alt={`Airplane Clock`}
              width={240}
              height={100}
              style={{ marginLeft: 5, alignSelf: "center" }}
            />
          </a>
        </div>

        <FooterComponent />
      </div>
    </React.Fragment>
  );
}
