import { Item } from "devextreme-react/tabs";
import { RiCalendarCheckLine } from "react-icons/ri";
import { FaRegChartBar } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { AiFillFacebook } from "react-icons/ai";
import "../site/site.scss";
import { Button, Tabs } from "devextreme-react";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useScreenSize } from "../../utils/media-query";
import Carousel from "../../components/carousel-component";
import CardComponent from "../../components/card-component";
import { HeaderComponent } from "../../components/header-site";
import { FooterComponent } from "../../components/footer-site";
const HomeBg = require("../../assets/images/Homebg.jpg");
const WizeWingsLogoM = require("../../assets/images/Logo02Blue6.png");
const DiscoverP1Image1 = require("../../assets/images/DiscoverP1Image1.png");
const DiscoverP1Image2 = require("../../assets/images/DiscoverP1Image2.png");
const SystemWeb = require("../../assets/images/system-web.png");
const FlightPlanningSmartAirport = require("../../assets/images/flightplanning-smart-airport.png");
//carrossel 1
const SmartAirport = require("../../assets/images/carrossel-app-images/image-1.png");
const NewFlight = require("../../assets/images/carrossel-app-images/image-2.png");
const AircraftRegister = require("../../assets/images/carrossel-app-images/image-3.png");
const Cost = require("../../assets/images/carrossel-app-images/image-4.png");

const WEB_IMAGE_1 = require("../../assets/images/carrossel-web-images/image-1.png");
const WEB_IMAGE_2 = require("../../assets/images/carrossel-web-images/image-2.png");
const WEB_IMAGE_3 = require("../../assets/images/carrossel-web-images/image-3.png");
const WEB_IMAGE_4 = require("../../assets/images/carrossel-web-images/image-4.png");
const WEB_IMAGE_5 = require("../../assets/images/carrossel-web-images/image-5.png");

const AIRPLANE_CLOCK = require("../../assets/images/mdi_airplane-clock.png");

const DATA_CAROUSEL_APP = [SmartAirport, NewFlight, AircraftRegister, Cost];
const DATA_CAROUSEL_WEB = [
  WEB_IMAGE_1,
  WEB_IMAGE_2,
  WEB_IMAGE_3,
  WEB_IMAGE_4,
  WEB_IMAGE_5,
];

export default function About() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <React.Fragment>
      <div
        className="site"
        style={{ flexDirection: "column", display: "flex", flex: 1 }}
      >
        <HeaderComponent />

        <div className="content content-container content-space-between">
          <div className="content-item">
            <h3>{t("aboutWizeWings")}</h3>
            <h5>{t("descriptionAboutUs")}</h5>
            {/* <h5>{t("DesignedForPilotsAndOwners")}</h5> */}
            <img
              src={SystemWeb}
              alt={`System Web`}
              width={"90%"}
              style={{ alignSelf: "center", marginTop: 20, minWidth: 300 }}
            />
          </div>
          <div className="content-item">
            <img
              src={FlightPlanningSmartAirport}
              alt={`System Web`}
              width={"80%"}
              style={{ alignSelf: "center", minWidth: 300 }}
            />
          </div>
        </div>

        <FooterComponent />
      </div>
    </React.Fragment>
  );
}
