import i18next from "i18next";

export const transformNumber = (aNum: number, aCurrency: string) => {
  try {
    return new Intl.NumberFormat(i18next.language, {
      style: "currency",
      currency: aCurrency || "USD",
    }).format(aNum);
  } catch (error) {
    console.log(error);
  }
};
