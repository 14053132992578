import { Routes, Route, Navigate } from "react-router-dom";
import Site from "./pages/site/site";
import About from "./pages/about/site";

export default function UnauthenticatedContent() {
  return (
    <Routes>
      <Route path="/" element={<Site />} />
      <Route path="/about" element={<About />} />

      <Route path="*" element={<Navigate to={"/"} />}></Route>
    </Routes>
  );
}
