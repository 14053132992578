import api from "../api/apiHelper";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ApiResponse } from "apisauce";
import { PlansList } from "../api/interfaces";

export default class UserService {
  static getListPlan = async (): Promise<PlansList[]> => {
    try {
      const DATA: PlansList[] = [
        {
          id: "1",
          title: "BASICPLAN",
          people: 4,
          aircraft: 4,
          price: [
            {
              id: "1",
              coin: "USD",
              value: 139,
              lng: "en-US",
            },
            {
              id: "2",
              coin: "BRL",
              value: 399,
              lng: "pt-BR",
            },
          ],
        },
        {
          id: "2",
          title: "PREMIUMPLAN",
          people: 4,
          aircraft: 4,
          price: [
            {
              id: "1",
              coin: "USD",
              value: 139,
              lng: "en-US",
            },
            {
              id: "2",
              coin: "BRL",
              value: 399,
              lng: "pt-BR",
            },
          ],
        },
      ];

      // await setTimeout(() => {
      return DATA;
      // },1000)

      return [];
      // return true;
      // const lRespAuth: ApiResponse<any> = await api.get(
      //   "V1/UserAir/loginUserAir"
      // );

      // if (lRespAuth.ok) {
      //   console.log(lRespAuth.data);
      //   await AsyncStorage.setItem("@wizewings:token", lRespAuth.data?.token);

      //   return true;
      // }
      // return [];
    } catch (e) {
      console.log("Erro [loginUserAir]: ", e);
      return [];
    }
  };
}
