import { Item } from "devextreme-react/tabs";
import { RiCalendarCheckLine } from "react-icons/ri";
import { FaRegChartBar } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { AiFillFacebook } from "react-icons/ai";
import "../../pages/site/site.scss";
import { Button, Tabs } from "devextreme-react";
import { t } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useScreenSize } from "../../utils/media-query";

export const FooterComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="footer footer-site">
      <div
        className="content-container"
        style={{ justifyContent: "space-between" }}
      >
        <div
          className="content-item font-footer"
          style={{ alignItems: "flex-start", minWidth: 300 }}
        >
          {/* <div>{t("socialMedia")}</div>
          <a href="https://www.instagram.com" target="_blank">
            <div
              style={{
                alignItems: "center",
                flexDirection: "row",
                display: "flex",
              }}
            >
              <BsInstagram color="white" size={20} />
              <div style={{ margin: 10 }}>@wizewings</div>
            </div>
          </a>
          <a href="https://www.facebook.com" target="_blank">
            <div
              style={{
                alignItems: "center",
                flexDirection: "row",
                display: "flex",
              }}
            >
              <AiFillFacebook color="white" size={25} />
              <div style={{ margin: 10 }}>WizeWings</div>
            </div>
          </a> */}
        </div>

        <div
          className="content-item font-footer"
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            minWidth: 300,
          }}
        >
          {/* <Link to="/">
            <div style={{ marginBottom: 10 }}>{t("faq")}</div>
          </Link>

          <Link to="/">
            <div style={{ marginBottom: 10 }}>{t("suport")}</div>
          </Link> */}
        </div>
        <div
          className="content-item font-footer"
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            minWidth: 300,
          }}
        >
          {/* <Link to="/about">
            <div>{t("ABOUTUS")}</div>
          </Link> */}
          {/* {`${t("contact")}: `} <b> contact@wizewings.aero </b> */}
        </div>
      </div>
    </div>
  );
};
