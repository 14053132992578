import { LoadIndicator } from "devextreme-react";
import React, { Component, useEffect, useRef, useState } from "react";
import { FaGasPump } from "react-icons/fa";
import "./card-component.scss";

export default function CardComponent(props: {
  IconComponent: any;
  width?: number | string;
  title: string;
  text: string;
  loading: boolean;
}) {
  const { IconComponent } = props;
  return (
    <div
      className="container-shadow"
      style={{
        minWidth: 200,
        // width: "100%",
        flex: 1,
        margin: 10,
      }}
    >
      <div style={{ padding: 10, display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: 20,
            paddingRight: 20,
            flex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flex: 1,
              minWidth: "100%",
            }}
          >
            <h6 style={{}}>{props.title}</h6>
            {props.IconComponent ? (
              <IconComponent className="icon-header" />
            ) : (
              <></>
            )}
          </div>

          {/* <FaGasPump size={25} className="icon-header" /> */}
          <p style={{}}>
            {props.loading ? (
              <LoadIndicator
                id="small-indicator"
                height={20}
                width={20}
                visible={props.loading}
              />
            ) : (
              props.text
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
