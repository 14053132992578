import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { changeLanguage } from "../i18n";
import type { LanguageContextType } from "../types";
import i18next from "i18next";

function LanguageProvider(props: React.PropsWithChildren<unknown>) {
  const [loadingLanguage, setLoadingLanguage] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);

  useEffect(() => {
    (async function () {
      // const lConnected = await UserService.isConnected();
      // setIsConnected(lConnected);
      // await checkUserIsConnected();
      // setLoading(false);
    })();
  }, []);

  const toggleLanguage = (aLang: string) => {
    // alert("entrou");
    setLoadingLanguage(true);
    changeLanguage(aLang);
    setCurrentLanguage(aLang);

    setTimeout(() => setLoadingLanguage(false), 500);
    return;
  };

  return (
    <LanguageContext.Provider
      value={{
        toggleLanguage,
        loadingLanguage,
        currentLanguage,
      }}
      {...props}
    />
  );
}

const LanguageContext = createContext<LanguageContextType>({
  loadingLanguage: false,
  currentLanguage: i18next.language,
} as LanguageContextType);
const useLanguage = () => useContext(LanguageContext);

export { LanguageProvider, useLanguage };
