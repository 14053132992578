import React, { useState, useEffect } from "react";
import "./Carousel.css";

const Carousel = (props: {
  images: string[];
  width?: number;
  minWidth: number;
}) => {
  const { images, width, minWidth } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000); // Mude para ajustar o intervalo de rotação (em milissegundos)

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div
      className="carousel-container"
      style={{ width: `${width ? width : 100}%`, minWidth: minWidth }}
    >
      <div
        className="carousel-slide"
        style={{ transform: `translateX(${-currentIndex * 100}%)` }}
      >
        {images.map((imageUrl, index) => (
          <div key={index} className="carousel-image">
            <img src={imageUrl} alt={`Imagem ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
