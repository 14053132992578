import { Item } from "devextreme-react/tabs";
import { RiCalendarCheckLine } from "react-icons/ri";
import { FaRegChartBar } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { AiFillFacebook } from "react-icons/ai";
import "../../pages/site/site.scss";
import { Button, Drawer, List, Tabs, Template } from "devextreme-react";
import { t } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { useScreenSize } from "../../utils/media-query";
import Carousel from "../../components/carousel-component";
import CardComponent from "../../components/card-component";
import UserPanel from "../user-panel/UserPanel";
import { OpenedStateMode, PanelLocation } from "devextreme/ui/drawer";
import { useLanguage } from "../../contexts/language";
import AsyncStorage from "@react-native-async-storage/async-storage";
const WizeWingsLogoM = require("../../assets/images/Logo02Blue6.png");
const BrasilImg = require("../../assets/images/icons8-brasil-48.png");
const UsaImg = require("../../assets/images/icons8-eua-48.png");

// export const navigation = [
//   {
//     id: 1,
//     text: t("APP"),
//     local: t("APP"),
//   },
//   {
//     id: 2,
//     text: t("WEB"),
//     local: t("WEB"),
//   },
//   {
//     id: 3,
//     text: t("PLANS"),
//     local: t("PLANS"),
//   },
//   {
//     id: 4,
//     text: t("ABOUTUS"),
//     local: "/about",
//   },
// ];

const NavigationList = (props: { toggleMenu: Function }) => {
  const navigate = useNavigate();
  const { toggleMenu } = props;
  const navigation = [
    {
      id: 1,
      text: t("APP"),
      local: t("APP"),
    },
    {
      id: 2,
      text: t("WEB"),
      local: t("WEB"),
    },
    {
      id: 3,
      text: t("PLANS"),
      local: t("PLANS"),
    },
    {
      id: 4,
      text: t("ABOUTUS"),
      local: "/about",
    },
  ];

  const scrollToDiv = (aId: string) => {
    toggleMenu();
    const minhaDiv = document.getElementById(aId);

    if (aId === t("ABOUTUS")) {
      navigate("/about");
      return;
    } else {
      navigate("/");
    }
    if (minhaDiv) {
      window.scrollTo({
        top: minhaDiv.offsetTop - 70,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="list" style={{ width: "200px" }}>
      <List
        dataSource={navigation}
        hoverStateEnabled={false}
        activeStateEnabled={false}
        focusStateEnabled={false}
        className="item-drawer-menu"
        onItemClick={(e) => scrollToDiv(`${e.itemData?.text}`)}
      />
    </div>
  );
};

export const HeaderComponent = () => {
  const { isSmall, isXSmall } = useScreenSize();
  const tabs = [
    {
      text: t("APP"),
    },
    {
      text: t("WEB"),
    },
    {
      text: t("PLANS"),
    },
    // {
    //   text: t("ABOUTUS"),
    // },
  ];
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate();
  const { toggleLanguage } = useLanguage();
  //drawer
  const [opened, setOpened] = useState(false);
  const [openedStateMode, setOpenedStateMode] =
    useState<OpenedStateMode>("shrink");
  const [revealMode, setRevealMode] = useState<PanelLocation>("left");
  const [position, setPosition] = useState("left");

  function onOutsideClick() {
    setOpened(false);
    return true;
  }

  const onPressCountry = async (aCOuntry: string) => {
    await AsyncStorage.setItem("@wizewings:lang", aCOuntry);
    toggleLanguage(aCOuntry);
  };

  const scrollToDiv = (aId: string) => {
    const minhaDiv = document.getElementById(aId);

    if (aId === t("ABOUTUS")) {
      navigate("/about");
      return;
    } else {
      navigate("/");
    }

    setTimeout(() => {
      if (minhaDiv) {
        window.scrollTo({
          top: minhaDiv.offsetTop - 70,
          behavior: "smooth",
        });
      }
    }, 300);
  };

  return (
    <header className={"header-site"}>
      <div className="header-items">
        {(isSmall || isXSmall) && (
          <Button
            // text={t("enter")}
            onClick={() => setOpened(!opened)}
            className="button-menu"
            icon="menu"

            // className="dxButton"
            // width={150}
          />
        )}
        <div
          className="item-header item-logo"
          onClick={() => {
            navigate("/");
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <img
            src={WizeWingsLogoM}
            style={{
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              // margin: "5px",
            }}
          />
        </div>

        {!isSmall && !isXSmall && (
          <Tabs
            dataSource={tabs}
            defaultSelectedIndex={selectedTab}
            onItemClick={(e) => scrollToDiv(e?.itemData?.text || "")}
            showNavButtons
          />
        )}

        <div className="item-header">
          <a
            href={`${
              window.location.protocol + "//" + window.location.host
            }/app`}
            target="_blank"
          >
            <Button
              text={t("enter")}
              onClick={() => null}
              className="custom-button-rounded"
              width={isSmall || isXSmall ? 100 : 150}
            />
          </a>
        </div>

        <div className="lang-container">
          <Button
            onClick={() => onPressCountry("pt-BR")}
            component={() => (
              <img
                src={BrasilImg}
                style={{
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  // margin: "5px",
                }}
                width={30}
              />
            )}
            stylingMode="text"
          />
        </div>

        <div className="lang-container">
          <Button
            onClick={() => onPressCountry("en-US")}
            component={() => (
              <img
                src={UsaImg}
                style={{
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  // margin: "5px",
                }}
                width={30}
              />
            )}
            stylingMode="text"
          />
        </div>
      </div>
      {(isSmall || isXSmall) && (
        <Drawer
          opened={opened}
          openedStateMode={openedStateMode}
          position={"left"}
          revealMode={"slide"}
          component={() => (
            <NavigationList toggleMenu={() => setOpened(!opened)} />
          )}
          closeOnOutsideClick={onOutsideClick}
          className="drawer-menu"
          height={opened ? "auto" : 0}
        ></Drawer>
      )}
    </header>
  );
};
