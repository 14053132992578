import i18next from "i18next";

import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enUs from "./locales/en/en-Us.json";
import ptBR from "./locales/pt/pt-BR.json";

const resources: any = {
  "pt-BR": ptBR,
  "en-US": enUs,
};

export const changeLanguage = (aLang: string) => {
  if (resources[aLang]) {
    console.log("mudando idioma...");
    i18next.changeLanguage(aLang);
  }
};

export const getCurrentLanguage = () => {
  return i18next.language;
};

export default i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // debug: true,
    fallbackLng: "en-US",
    resources,
    lng: navigator.language,
    interpolation: {
      escapeValue: false,
    },
  });
